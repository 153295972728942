import { defineStore } from "pinia"
import { useNuxtApp } from "#app"
import moment from "moment-timezone"
import {
  CameraExid,
  DateType,
  DetectionsFilters,
  Segment,
  SegmentSimilarityResult,
  TimelineDateInterval,
  TimelineGroup,
  TimelineGroupId,
  TimelinePlayerGroupConfig,
  TimelinePrecision,
} from "@evercam/shared/types"
import { TimelineColors } from "@evercam/shared/constants/timeline"
import { useTimelineStore } from "@/stores/timeline/timeline"
import { TimelineChartType } from "@evercam/ui"
import { EvercamLabsApi } from "@evercam/shared/api/evercamLabsApi"
import { useRecordingsStore } from "@/stores/recordings"
import { TimelineSegmentsIntervalsProvider } from "@evercam/shared/components/timelinePlayer/providers/timelineSegmentsProvider"
import { useAccountStore } from "@/stores/account"

const groupId = TimelineGroupId.ObjectInspector

interface TimelineObjectInspectorGroupState {
  showObjectInspector: boolean
  segments: Segment[]
  selectedSegment: Segment | null
  isSearching: boolean
  similarSegments: SegmentSimilarityResult[]
  firstSeen: DateType | null
  lastSeen: DateType | null
  isActive: boolean
}

export const useTimelineObjectInspectorGroupStore = defineStore({
  id: "timelineObjectInspectorGroup",
  state: (): TimelineObjectInspectorGroupState => ({
    showObjectInspector: false,
    segments: [],
    selectedSegment: null,
    isSearching: false,
    similarSegments: [],
    firstSeen: null,
    lastSeen: null,
    isActive: false,
  }),
  actions: {
    toggleInspector() {
      this.isActive = !this.isActive
      if (this.isActive) {
        this.fetchSegmentsForSelectedTimestamp()
      } else {
        this.selectedSegment = null
      }
    },
    onSegmentSelected(segment: Segment) {
      this.selectedSegment = segment
      if (!segment) {
        this.similarSegments = []
        this.firstSeen = null
        this.lastSeen = null
        this.isSearching = false
      }
    },
    async onTimestampSelected() {
      if (this.isActive) {
        await this.fetchSegmentsForSelectedTimestamp()
      }
    },
    async fetchSegmentsForSelectedTimestamp() {
      try {
        let params = this.closestValidDateInterval as DetectionsFilters

        const segmentsByLabel =
          await EvercamLabsApi.detections.getSegmentsMasks(
            this.selectedCameraExid,
            params
          )

        this.segments = Object.values(segmentsByLabel).flat()
      } catch (error) {
        console.error("Failed to fetch detections trackings.", error)
      }
    },
    async searchSegmentHistory() {
      this.isSearching = true
      this.similarSegments = []
      const centerTimestamp = this.selectedTimestamp
      const initialLeftInterval = {
        fromDate: moment(centerTimestamp).subtract(1, "days").format(),
        toDate: centerTimestamp,
      }

      const initialRightInterval = {
        fromDate: centerTimestamp,
        toDate: moment(centerTimestamp).add(1, "days").format(),
      }

      this.showObjectInspector = true
      await this.searchRecursively(initialLeftInterval, initialRightInterval)

      this.isSearching = false
    },
    getNextLeftInterval(
      currentInterval: TimelineDateInterval
    ): TimelineDateInterval {
      return {
        fromDate: moment(currentInterval.fromDate).subtract(1, "days").format(),
        toDate: moment(currentInterval.toDate).subtract(1, "days").format(),
      }
    },
    getNextRightInterval(
      currentInterval: TimelineDateInterval
    ): TimelineDateInterval {
      return {
        fromDate: moment(currentInterval.fromDate).add(1, "days").format(),
        toDate: moment(currentInterval.toDate).add(1, "days").format(),
      }
    },
    updateFirstSeen(newFirstSeen?: string) {
      if (
        !this.firstSeen ||
        (newFirstSeen &&
          new Date(newFirstSeen).getTime() < new Date(this.firstSeen).getTime())
      ) {
        this.firstSeen = newFirstSeen
      }
    },
    updateLastSeen(newLastSeen?: string) {
      if (
        !this.lastSeen ||
        (newLastSeen &&
          new Date(newLastSeen).getTime() > new Date(this.lastSeen).getTime())
      ) {
        this.lastSeen = newLastSeen
      }
    },
    async searchRecursively(
      leftInterval: TimelineDateInterval,
      rightInterval: TimelineDateInterval,
      consecutiveFailures: number,
      maxConsecutiveFailures: number = 3
    ): Promise<void> {
      if (consecutiveFailures >= maxConsecutiveFailures) {
        return
      }

      const { similarSegments, firstSeen, lastSeen } =
        await EvercamLabsApi.detections.getSimilarSegmentsInTimeIntervals({
          cameraExid: this.selectedCameraExid,
          segmentId: this.selectedSegment.id,
          leftInterval,
          rightInterval,
          maxDistance: 0.1,
          areaTolerance: 0.1,
        })

      if (similarSegments.length) {
        this.similarSegments = this.similarSegments
          .concat(similarSegments)
          .sort((a, b) =>
            moment(a.timestamp).isAfter(moment(b.timestamp)) ? 1 : -1
          )
        consecutiveFailures = 0
      } else {
        consecutiveFailures++
      }

      this.updateFirstSeen(firstSeen)
      this.updateLastSeen(lastSeen)

      await this.searchRecursively(
        this.getNextLeftInterval(leftInterval),
        this.getNextRightInterval(rightInterval),
        consecutiveFailures,
        maxConsecutiveFailures
      )
    },
  },
  getters: {
    isDisabled(): boolean {
      return !this.segments.length
    },
    isVisible(): boolean {
      return this.isActive
    },
    isRestricted(): boolean {
      return !(
        (this.$permissions.user.is.admin() ||
          useAccountStore().email === "michael.joseph@ftsquared.com" ||
          useAccountStore().email === "mjoseph@echelon-dc.com") &&
        this.$permissions.camera.has.objectInspector()
      )
    },
    timezone(): string {
      return useTimelineStore().selectedCamera?.timezone || "UTC"
    },
    selectedCameraExid(): CameraExid {
      return useTimelineStore().selectedCamera?.exid || ""
    },
    objectInspectorGroup(): Record<string, TimelinePlayerGroupConfig> {
      if (
        !this.showObjectInspector ||
        !this.similarSegments.length ||
        !this.selectedSegment
      ) {
        return { availableDates: this.availableDatesGroup }
      }

      const selectedSegmentPresenceInterval = this.similarSegments.reduce(
        (acc, { segment }) => {
          const timestamp = new Date(segment.timestamp)

          if (!acc.startDate || timestamp < acc.startDate) {
            acc.startDate = timestamp
          }

          if (!acc.endDate || timestamp > acc.endDate) {
            acc.endDate = timestamp
          }

          return acc
        },
        { startDate: null, endDate: null }
      )

      return {
        similarSegments: {
          label: useNuxtApp()
            .vue2App.$i18n.t(`content.etimeline.labels.${groupId}_history`)
            .toString(),
          color: TimelineColors.primary,
          events: [selectedSegmentPresenceInterval],
          chartType: TimelineChartType.Bars,
          isLoading: this.isSearching,
          getChartType: (_p) => TimelineChartType.Bars,
        },
        availableDates: this.availableDatesGroup,
      }
    },
    $permissions() {
      return useNuxtApp().nuxt2Context.$permissions
    },
    group(): TimelineGroup {
      return {
        id: groupId,
        isDisabled: this.isDisabled,
        isVisible: this.isVisible,
        isRestricted: this.isRestricted,
        isPersistent: true,
        hasMultipleGroups: true,
        timelineConfig: this.objectInspectorGroup,
      }
    },
    selectedTimestamp(): DateType {
      return useRecordingsStore().selectedTimestamp
    },

    availableDatesGroup(): TimelinePlayerGroupConfig {
      return {
        label: "Available dates",
        color: TimelineColors.success,
        chartType: TimelineChartType.Bars,
        getChartType: (_p) => TimelineChartType.Bars,
        provider: new TimelineSegmentsIntervalsProvider({
          cameraExid: this.selectedCameraExid,
          timezone: this.timezone,
          labelFilterFn: (_l) => true,
          groupedMode: true,
          fixedPrecision: TimelinePrecision.Hour,
        }),
      }
    },
    closestValidDateInterval(): TimelineDateInterval {
      const startTime = moment
        .tz(this.selectedTimestamp, this.timezone)
        .startOf("hour")
      const validStartHour = 6
      const validEndHour = 21

      const getClosestValidTime = (time) => {
        const hour = time.hour()
        if (hour < validStartHour) {
          return time.clone().hour(validStartHour).startOf("hour")
        } else if (hour >= validEndHour) {
          return time
            .clone()
            .hour(validEndHour - 1)
            .startOf("hour")
        }

        return time.clone()
      }

      const startHour = getClosestValidTime(startTime)

      return {
        fromDate: startHour.toISOString(),
        toDate: moment(startHour).endOf("hour").toISOString(),
      }
    },
  },
})
